var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pushNotice__form"},[_c('v-card',{attrs:{"lazy-validation":""}},[_c('v-card-title',{staticClass:"modal__title"},[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.dialogEdit ? _vm.$t('action.edit_notice') : _vm.$t('action.new_notice')))]),_c('button',{staticClass:"btn-close",attrs:{"type":"button"},on:{"click":_vm.clickCancel}},[_c('v-icon',{staticClass:"fa-solid fa-xmark"})],1)]),_c('a-form',{attrs:{"form":_vm.form}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('label',{staticClass:"pushNoti__label"},[_vm._v(_vm._s(_vm.$t('cms.sending_time')))]),_c('div',{staticClass:"pushNoti__datetime"},[_c('a-form-item',[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                                'sending_date',
                                                {
                                                    initialValue: _vm.cuItem.sending_unix_timestamp,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: _vm.$t('form.sending_date_required'),
                                                        } ],
                                                } ]),expression:"[\n                                                'sending_date',\n                                                {\n                                                    initialValue: cuItem.sending_unix_timestamp,\n                                                    rules: [\n                                                        {\n                                                            required: true,\n                                                            message: $t('form.sending_date_required'),\n                                                        },\n                                                    ],\n                                                },\n                                            ]"}],attrs:{"placeholder":_vm.$t('cms.select_date'),"format":"YYYY/MM/DD","disabledDate":_vm.disabledDate}})],1),_c('a-form-item',[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                                'sending_time',
                                                {
                                                    initialValue: _vm.cuItem.sending_unix_timestamp,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: _vm.$t('form.sending_time_required'),
                                                        } ],
                                                } ]),expression:"[\n                                                'sending_time',\n                                                {\n                                                    initialValue: cuItem.sending_unix_timestamp,\n                                                    rules: [\n                                                        {\n                                                            required: true,\n                                                            message: $t('form.sending_time_required'),\n                                                        },\n                                                    ],\n                                                },\n                                            ]"}],attrs:{"placeholder":_vm.$t('cms.select_time'),"format":"HH:mm","disabledHours":_vm.disabledHours,"disabledMinutes":_vm.disabledMinutes}})],1)],1)])]),_c('v-col',{attrs:{"cols":"12"}},[_c('a-form-item',[_c('label',{staticClass:"pushNoti__label"},[_vm._v(_vm._s(_vm.$t('cms.frequency')))]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'frequency',
                                        {
                                            initialValue: _vm.cuItem.frequency,
                                            rules: [{ required: true, message: _vm.$t('form.frequency_required') }],
                                        } ]),expression:"[\n                                        'frequency',\n                                        {\n                                            initialValue: cuItem.frequency,\n                                            rules: [{ required: true, message: $t('form.frequency_required') }],\n                                        },\n                                    ]"}]},[_c('a-select-option',{attrs:{"value":"ONE_TIME"}},[_vm._v(_vm._s(_vm.$t('cms.ONE_TIME')))]),_c('a-select-option',{attrs:{"value":"REGULAR_EVERY_7_DAYS"}},[_vm._v(" "+_vm._s(_vm.$t('cms.REGULAR_EVERY_7_DAYS'))+" ")]),_c('a-select-option',{attrs:{"value":"REGULAR_EVERY_30_DAYS"}},[_vm._v(" "+_vm._s(_vm.$t('cms.REGULAR_EVERY_30_DAYS'))+" ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('a-form-item',[_c('label',{staticClass:"pushNoti__label"},[_vm._v(_vm._s(_vm.$t('cms.message_title')))]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'message_title',
                                        {
                                            initialValue: _vm.cuItem.message_title,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: _vm.$t('form.message_title_required'),
                                                    whitespace: true,
                                                } ],
                                        } ]),expression:"[\n                                        'message_title',\n                                        {\n                                            initialValue: cuItem.message_title,\n                                            rules: [\n                                                {\n                                                    required: true,\n                                                    message: $t('form.message_title_required'),\n                                                    whitespace: true,\n                                                },\n                                            ],\n                                        },\n                                    ]"}],attrs:{"max-length":255}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('a-form-item',[_c('label',{staticClass:"pushNoti__label"},[_vm._v(_vm._s(_vm.$t('cms.message_content')))]),_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'message_content',
                                        {
                                            initialValue: _vm.cuItem.message_content,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: _vm.$t('form.message_content_required'),
                                                    whitespace: true,
                                                } ],
                                        } ]),expression:"[\n                                        'message_content',\n                                        {\n                                            initialValue: cuItem.message_content,\n                                            rules: [\n                                                {\n                                                    required: true,\n                                                    message: $t('form.message_content_required'),\n                                                    whitespace: true,\n                                                },\n                                            ],\n                                        },\n                                    ]"}],attrs:{"rows":5,"max-length":1000}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.clickCancel}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.clickSave}},[_vm._v(" "+_vm._s(_vm.$t('form.save')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }