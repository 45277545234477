<template>
    <div class="cms-notice">
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">{{ $t('table_title.Notification') }}</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <v-data-table
                            :headers="tableColumns"
                            :items="notifications"
                            class="elevation-1"
                            hide-default-footer
                            :loading="isLoading"
                            :loading-text="$t('cms.loading')"
                            :page="page"
                            :no-data-text="$t('cms.no_data_available')"
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-dialog v-model="dialogCU" persistent max-width="600px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="primary"
                                                dark
                                                class="mb-2"
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="onAddNewItem()"
                                                small
                                            >
                                                <v-icon small> mdi-plus </v-icon>
                                                {{ $t('cms.add_new') }}
                                            </v-btn>
                                        </template>
                                        <ModalNotiPush
                                            :cuItem="cuItem"
                                            :formTitle="formTitle"
                                            :closeCU="closeCU"
                                            :save="onSaveItem"
                                            :propsField="propsField"
                                            :columns="tableColumns"
                                            :dialogEdit="dialogEdit"
                                        >
                                        </ModalNotiPush>
                                    </v-dialog>
                                    <v-spacer></v-spacer>
                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <ModalDelete :closeDelete="closeDelete" :deleteItemConfirm="deleteItemConfirm">
                                        </ModalDelete>
                                    </v-dialog>
                                    <v-dialog v-model="dialogNoti" max-width="500px">
                                        <ModalNoti :close="closeNoti" :message="formMessage"></ModalNoti>
                                    </v-dialog>

                                    <div class="search">
                                        <a-select :default-value="selectedField" @change="handleChangeSelect">
                                            <a-select-option
                                                v-for="(item, index) in fieldsSearch"
                                                :key="index"
                                                :value="item.value"
                                            >
                                                {{ item.title }}
                                            </a-select-option>
                                        </a-select>
                                        <a-input-search
                                            v-model="search"
                                            :placeholder="$t('header.search')"
                                            @change="onSearchItem"
                                        />
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.message_title`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{ item.message_title }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.message_content`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{ item.message_content }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.frequency`]="{ item }">
                                <div class="my-2">
                                    <span>{{ $t(`cms.${item.frequency}`) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.sending_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(+item.sending_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                <div class="my-2">
                                    <span>{{ convertTimeZone(item.created_at) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn color="info" class="mr-2" small @click="onPushNoti(item)">
                                    <v-icon small> mdi mdi-arch </v-icon>
                                    &nbsp;{{ $t('cms.push_notice') }}
                                </v-btn>
                                <v-btn color="info" class="mr-2" small @click="onUpdateItem(item)">
                                    <v-icon small> mdi-pencil </v-icon>
                                    &nbsp;{{ $t('form.edit') }}
                                </v-btn>
                                <v-btn color="error" dark small @click="onDeleteItem(item)">
                                    <v-icon small> mdi-delete </v-icon>
                                    &nbsp;{{ $t('form.delete') }}
                                </v-btn>
                            </template>
                        </v-data-table>
                        <div class="text-center py-5">
                            <f-pagination
                                v-model="page"
                                :pageSize="notificationPagination.pageSize"
                                :totalItems="notificationPagination.totalItems"
                                :disabled="isLoading"
                            />
                        </div>
                    </template>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ModalDelete from '../../components/CMS/Modal/Delete.vue';
import ModalNotiPush from '../../components/CMS/Modal/Pushnoti.vue';
import ModalNoti from '../../components/CMS//Modal/Noti.vue';

import { CRUD } from '../../constants';
import { mixinFormats, mixinToasts, minxinTranslate, mixinLoadings } from '../../mixins';
import moment from 'moment';
import * as _ from 'lodash';

export default {
    mixins: [mixinFormats, mixinToasts, minxinTranslate, mixinLoadings],
    components: { ModalDelete, ModalNotiPush, ModalNoti },
    data() {
        return {
            tableTitle: this.$t('cms.Notification'),
            tableColumns: [
                { text: this.$t('cms.message_title'), value: 'message_title', sortable: false, width: '200px' },
                { text: this.$t('cms.message_content'), value: 'message_content', sortable: false, width: '200px' },
                { text: this.$t('cms.frequency'), value: 'frequency', sortable: false, width: '200px' },
                { text: this.$t('cms.sending_time'), value: 'sending_unix_timestamp', sortable: false, width: '175px' },
                {
                    text: this.$t('form.created_at'),
                    value: 'created_at',
                    sortable: false,
                    width: '175px',
                },
                { text: this.$t('cms.action'), value: 'actions', sortable: false, width: '350px' },
            ],
            fieldsSearch: [
                {
                    title: this.$t('cms.message_title'),
                    value: 'message_title',
                },
                {
                    title: this.$t('cms.message_content'),
                    value: 'message_content',
                },
            ],
            selectedField: 'message_title',
            search: '',
            page: 1,
            cuIndex: -1,
            cuItem: {},
            dialogCU: false,
            dialogDelete: false,
            dialogNoti: false,
            dialogEdit: false,
            noImage: require('../../assets/images/no_image.jpg'),
            formMessage: '',
        };
    },
    created() {
        this.hiddenFieldModal(this.dialogEdit);
    },
    computed: {
        ...mapState('notification', ['isLoading', 'notifications', 'notificationPagination']),
        formTitle() {
            return this.cuIndex === -1 ? this.$t('cms.new_item') : this.$t('cms.edit_item');
        },
    },
    async mounted() {
        await this.getList({ page: this.page, field: this.selectedField, search: this.search });
    },
    watch: {
        page(val) {
            this.getList({ page: val, field: this.selectedField, search: this.search });
        },
        dialogCU(val) {
            val || this.closeCU();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        dialogNoti(val) {
            val || this.closeNoti();
        },
        dialogEdit(val) {
            this.hiddenFieldModal(val);
        },
    },
    methods: {
        ...mapActions('notification', ['getNotifications', 'postNewNoti', 'putNoti', 'deleteOne', 'pushNoti']),

        async getList(data) {
            this.page = data?.page || 1;
            this.selectedField = data?.field;
            this.search = data?.search || '';
            await this.getNotifications(data);
        },

        initialize() {
            this.getList({ page: 1, field: 'title', search: '' });
        },

        hiddenFieldModal(val) {
            if (val === true) {
                this.propsField = this.tableColumns.filter(
                    (s) => s.value !== 'password' && s.value !== 'created_at' && s.value !== 'updated_at',
                );
            } else {
                this.propsField = this.tableColumns.filter((s) => s.value !== 'created_at' && s.value !== 'updated_at');
            }
        },

        onAddNewItem() {
            this.dialogEdit = false;
        },

        async onPushNoti(item) {
            this.showLoading();

            const response = await this.pushNoti(item.id);

            if (item.frequency === 'ONE_TIME') {
                await this.getNotifications({ page: this.page, field: this.selectedField, search: this.search });
            }

            this.hideLoading();

            if (response?.isSuccess) {
                this.showSuccess(CRUD.PUSH_NOTICE);
            } else {
                this.showError(response?.message);
            }
        },

        onUpdateItem(item) {
            this.dialogEdit = true;
            this.cuIndex = this.notifications.indexOf(item);
            this.cuItem = Object.assign({}, { ...item, sending_unix_timestamp: moment(+item.sending_unix_timestamp) });
            this.dialogCU = true;
        },

        onDeleteItem(item) {
            this.cuIndex = this.notifications.indexOf(item);
            this.cuItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        async deleteItemConfirm() {
            this.closeDelete();
            this.showLoading();
            const res = await this.deleteOne(this.cuItem.id);
            if (res?.isSuccess) {
                await this.getNotifications({ page: this.page, field: this.selectedField, search: this.search });
                this.showSuccess(CRUD.DELETE);
            } else {
                this.showError(res?.message);
            }
            this.hideLoading();
        },

        closeCU() {
            this.dialogCU = false;
            this.$nextTick(() => {
                this.cuItem = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.cuItem = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },

        closeNoti() {
            this.dialogNoti = false;
        },

        async onSaveItem(data, callback) {
            let response = {};
            let getListParams = {};

            this.showLoading();

            if (this.cuIndex === -1) {
                response = await this.postNewNoti(data);
                getListParams = { page: 1, field: 'title', search: '' };
            } else {
                response = await this.putNoti(data);
                getListParams = { page: this.page, field: this.selectedField, search: this.search };
            }

            callback(response?.isSuccess);
            if (response?.isSuccess) {
                await this.getNotifications(getListParams);
                this.showSuccess(this.cuIndex === -1 ? CRUD.CREATE : CRUD.UPDATE);
            } else {
                this.showError(response?.message);
            }

            this.hideLoading();
        },

        onSearchItem: _.debounce(function (e) {
            this.getNotifications({ page: 1, field: this.selectedField, search: this.search });
        }, 500),

        handleChangeSelect(value) {
            this.getNotifications({ page: 1, field: value, search: this.search });
        },
    },
};
</script>

<style lang="scss">
.search {
    & .ant-select {
        margin-right: 10px;
        width: 175px;
    }

    & .ant-input-search {
        width: 500px;
    }
}

.cms-notice {
    & .elevation-1 {
        box-shadow: unset !important;
    }
}
</style>
