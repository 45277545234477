<template>
    <div class="pushNotice__form">
        <v-card lazy-validation>
            <v-card-title class="modal__title">
                <span class="text-h6">{{ dialogEdit ? $t('action.edit_notice') : $t('action.new_notice') }}</span>
                <button type="button" class="btn-close" @click="clickCancel">
                    <v-icon class="fa-solid fa-xmark"></v-icon>
                </button>
            </v-card-title>

            <a-form :form="form">
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <div>
                                    <label class="pushNoti__label">{{ $t('cms.sending_time') }}</label>
                                    <div class="pushNoti__datetime">
                                        <a-form-item>
                                            <a-date-picker
                                                :placeholder="$t('cms.select_date')"
                                                format="YYYY/MM/DD"
                                                :disabledDate="disabledDate"
                                                v-decorator="[
                                                    'sending_date',
                                                    {
                                                        initialValue: cuItem.sending_unix_timestamp,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: $t('form.sending_date_required'),
                                                            },
                                                        ],
                                                    },
                                                ]"
                                            />
                                        </a-form-item>
                                        <a-form-item>
                                            <a-time-picker
                                                :placeholder="$t('cms.select_time')"
                                                format="HH:mm"
                                                :disabledHours="disabledHours"
                                                :disabledMinutes="disabledMinutes"
                                                v-decorator="[
                                                    'sending_time',
                                                    {
                                                        initialValue: cuItem.sending_unix_timestamp,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: $t('form.sending_time_required'),
                                                            },
                                                        ],
                                                    },
                                                ]"
                                            />
                                        </a-form-item>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <a-form-item>
                                    <label class="pushNoti__label">{{ $t('cms.frequency') }}</label>
                                    <a-select
                                        v-decorator="[
                                            'frequency',
                                            {
                                                initialValue: cuItem.frequency,
                                                rules: [{ required: true, message: $t('form.frequency_required') }],
                                            },
                                        ]"
                                    >
                                        <a-select-option value="ONE_TIME">{{ $t('cms.ONE_TIME') }}</a-select-option>
                                        <a-select-option value="REGULAR_EVERY_7_DAYS">
                                            {{ $t('cms.REGULAR_EVERY_7_DAYS') }}
                                        </a-select-option>
                                        <a-select-option value="REGULAR_EVERY_30_DAYS">
                                            {{ $t('cms.REGULAR_EVERY_30_DAYS') }}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </v-col>
                            <v-col cols="12">
                                <a-form-item>
                                    <label class="pushNoti__label">{{ $t('cms.message_title') }}</label>
                                    <a-input
                                        :max-length="255"
                                        v-decorator="[
                                            'message_title',
                                            {
                                                initialValue: cuItem.message_title,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: $t('form.message_title_required'),
                                                        whitespace: true,
                                                    },
                                                ],
                                            },
                                        ]"
                                    />
                                </a-form-item>
                            </v-col>
                            <v-col cols="12">
                                <a-form-item>
                                    <label class="pushNoti__label">{{ $t('cms.message_content') }}</label>
                                    <a-textarea
                                        :rows="5"
                                        :max-length="1000"
                                        v-decorator="[
                                            'message_content',
                                            {
                                                initialValue: cuItem.message_content,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: $t('form.message_content_required'),
                                                        whitespace: true,
                                                    },
                                                ],
                                            },
                                        ]"
                                    />
                                </a-form-item>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn color="blue darken-1" text @click="clickCancel">{{ $t('cancel') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="clickSave"> {{ $t('form.save') }}</v-btn>
                </v-card-actions>
            </a-form>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment';
import { mixinRules, minxinTranslate, mixinFormats, mixinToasts } from '../../../mixins';

export default {
    mixins: [mixinRules, minxinTranslate, mixinFormats, mixinToasts],
    components: {},
    props: {
        cuItem: { type: Object },
        formTitle: { type: String },
        closeCU: { type: Function },
        save: { type: Function },
        propsField: { type: Array },
        dialogEdit: { type: Boolean },
    },
    data() {
        return {
            form: this.$form.createForm(this, { name: 'notice-form' }),
            errorMessages: '',
            formHasErrors: false,
        };
    },
    methods: {
        disabledDate(current) {
            return current && moment().startOf('day') >= current;
        },

        disabledHours() {
            let hours = [];
            if (moment().valueOf() > moment(this.form.getFieldValue('sending_date')).valueOf()) {
                for (var i = 0; i < moment().hour(); i++) {
                    hours.push(i);
                }
            }
            return hours;
        },

        disabledMinutes(selectedHour) {
            let minutes = [];
            if (
                selectedHour === moment().hour() &&
                moment().valueOf() > moment(this.form.getFieldValue('sending_date')).valueOf()
            ) {
                for (var i = 0; i < moment().minute(); i++) {
                    minutes.push(i);
                }
            }
            return minutes;
        },

        clickCancel() {
            this.closeCU();
            this.form.resetFields();
        },

        clickSave(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    const sending_date = moment(values.sending_date).startOf('days').valueOf();
                    const sending_time =
                        moment(values.sending_time).set({ seconds: 0 }).valueOf() -
                        moment(values.sending_time).startOf('days').valueOf();

                    if (sending_date + sending_time < moment().valueOf()) {
                        this.showToast('error', this.$t('error.incorrect_sending_time'));
                        return;
                    }

                    const body = {
                        ...this.cuItem,
                        sending_unix_timestamp: sending_date + sending_time,
                        frequency: values.frequency,
                        message_title: values.message_title.trim(),
                        message_content: values.message_content.trim(),
                    };

                    this.save(body, (isSuccess) => {
                        if (isSuccess) this.clickCancel();
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss">
.ant-calendar .ant-calendar-ok-btn {
    height: auto !important;
}

.pushNotice__form {
    & .ant-form-item {
        margin-bottom: 0;
    }
}

.pushNoti__label {
    display: block;
    margin-bottom: 5px;
    line-height: 1;
}

.pushNoti__datetime {
    display: flex;
    gap: 10px;
    & input {
        width: 100%;
        font-weight: bold;
    }
}

.modal__title {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    .btn-close i {
        color: #aaa !important;
        transition: all 0.4s ease;
    }
    .btn-close:hover i {
        color: #000 !important;
    }
}
</style>
